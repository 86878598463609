import React from "react";
var __jsx = React.createElement;
import { useContext, useMemo } from 'react';
import { DEFAULT_MAP_SETTINGS, MAP_ID } from './constants';
import Map from '../../molecules/Map';
import { Context } from './MapStateProvider';
function MapView(_ref) {
  var toggleMap = _ref.toggleMap;
  var _useContext = useContext(Context),
    allCoorLocations = _useContext.allCoorLocations,
    isLoadingInfoWindow = _useContext.isLoadingInfoWindow,
    selectedLocation = _useContext.selectedLocation,
    handleSelectLocation = _useContext.handleSelectLocation,
    selectedLocationKey = _useContext.selectedLocationKey,
    setSelectedLocationKey = _useContext.setSelectedLocationKey,
    handleReStartMap = _useContext.handleReStartMap,
    reStartMap = _useContext.reStartMap;
  var filteredLocations = useMemo(function () {
    if (!allCoorLocations) return null;
    // Modify this when the filters are implemented
    return allCoorLocations === null || allCoorLocations === void 0 ? void 0 : allCoorLocations.filter(function (_, index) {
      return index < 2000;
    }).map(function (location) {
      return location;
    });
  }, [allCoorLocations]);
  return __jsx("div", {
    className: "nva-maps-hhpets__map-view map-view ".concat(toggleMap ? 'toggled is-toggled' : '')
  }, __jsx(Map, {
    locations: filteredLocations,
    mapId: MAP_ID,
    selectedLocation: selectedLocation,
    handleSelectLocation: handleSelectLocation,
    isLoadingInfoWindow: isLoadingInfoWindow,
    defaultMap: DEFAULT_MAP_SETTINGS,
    selectedLocationKey: selectedLocationKey,
    setSelectedLocationKey: setSelectedLocationKey,
    onResetMap: handleReStartMap,
    reStartMap: reStartMap,
    hasOldIcons: true
  }));
}
export default MapView;